.feature-style1 {
  background-color: $white-color;
  padding: 50px 40px;
  box-shadow: 0px 18px 13px rgba(234, 240, 250, 0.5);
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;

  &:before {
    content: '';
    position: absolute;
    left: 40px;
    bottom: 0;
    height: 3px;
    width: calc(100% - 80px);
    background-color: $theme-color;
  }

  .feature-icon {
    margin: 0 0 15px 0;
  }

  .feature-title {
    margin: 0 0 13px 0;
  }

  .feature-text {
    margin: 0 0 20px 0;
  }

  &:hover {
    .vs-btn {
      background-color: $theme-color;
      color: $white-color;
    }
  }
  
}


.feature-style2 {
  background-color: $white-color;
  padding: 40px 40px 32px 40px;
  border-radius: 5px;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  margin: 0 0 30px 0;

  .feature-icon {
    background-color: $secondary-color;
    width: 110px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 5px;
    margin: 0 0 22px 0;
    transition: all ease 0.4s;

    img {
      transition: all ease 0.4s;
      filter: none;
    }
  }

  .feature-title {
    margin: 0 0 10px 0;
  }

  &:hover {
    .feature-icon {
      background-color: $theme-color;

      img {
        -webkit-filter: brightness(0) invert(1); /* Safari 6.0 - 9.0 */
        filter: brightness(0) invert(1);
      }
    }
  }
}

.feature-slide2 {
  margin-top: -30px;
  margin-bottom: -30px;

  .feature-style2 {
    box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
    margin: 30px 0 30px 0;
  }
}


@media (min-width: $lg) {
  .feature-wrap1 {
    &.space-top {
      padding-top: 1px;
    }

    >.container {
      position: relative;
      z-index: 36;
      margin-top: -75px;
    }
  }

  .feature-wrap2 {
    z-index: 3;
    position: relative;
    margin-bottom: -30px;
  }
}

@include lg {
  .feature-slide2 {
    .feature-style2 {
      box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
    }
  }
}

@include md {
  .feature-style1 {
    padding: 40px 30px;
  }
}

@include sm {
  .feature-style1 {
    padding: 40px 20px;
  }


  .feature-style2 {
    padding: 40px 25px 32px 25px;
  }
}